<template>
    <!-- <div class="container">
      <div class="">
        <p></p>-->
        <!-- slider -->
        <div v-if="offers" class="trending-slider rounded">
          <div
            v-for="offer in offers"
            v-bind:key="offer.id"
            class="osahan-slider-item"
          >
            <div
              class="
                list-card
                bg-white
                h-100
                rounded
                overflow-hidden
                position-relative
                shadow-sm
              "
            >
              <div class="list-card-image">
                <a 
                data-toggle="modal"
                data-target="#extras"
                >
                  <img
                    :src="$store.state.baseURL + '/' + offer.image"
                    class="img-fluid item-img w-100"
                  />
                </a>
              </div>
              <div class="p-3 position-relative">
                <div class="list-card-body">
                  <h6 class="mb-1">
                    <a href="checkout" class="text-black">{{
                      offer.name_en
                    }}</a>
                  </h6>
                  <p class="text-gray mb-3">{{ offer.description_en }}</p>
                  <p v-for="size in offer.info" v-bind:key="size.id">
                    {{ size.size_en }}
                    <span class="text-gray m-0">
                      <span class="text-black-50">{{ size.price.price }}</span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- </div>
    </div> -->
</template>
<script>
export default {
    props:['offers']
}
</script>