<template>
  <div ref="app" id="app">
    <site-header :restaurant="restaurant" />
    <router-view />
    <site-footer :AboutCo="AboutCo" :Copyrights="CRights"></site-footer>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { mapState } from 'vuex';
import SiteFooter from './components/siteFooter.vue';
import SiteHeader from "./components/siteHeader.vue";

export default {
  components: {
    SiteHeader,
    SiteFooter,
  },
  data() {
    return {
      restaurant: null,
      AboutCo: '',
      CRights: '',
    };
  },
  computed: mapState(['curOrder']),
  methods: {
    ...mapActions(["getUser", "getSelArea", "getSelCity", "UpdateCurrentOrder"]),
    getRest(area) {
      let url =
        this.$store.state.baseURL +
        "/api/restaurants/" +
        area;
      let request = new Request(url, { method: "GET" });
      fetch(request)
        .then((r) => {
          if (r.ok) {
            return r.json();
          }
        })
        .then((r) => {
          if (r.response) {
            this.restaurant = JSON.parse(JSON.stringify(r)).data.restaurants.find((f) => f.id == window.RestId);
            // this.restaurant = JSON.parse(JSON.stringify(r)).data.restaurants[0];
            this.$store.commit('setRestaurant', this.restaurant)

          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  watch: {
    $route(to, from) {
      if (to || from) //console.log("routing");
        this.$store.dispatch("getUser");
      this.$store.dispatch("getSelArea");
      this.$store.dispatch("getSelCity");
    },
    "$store.state.selArea"(val) {
      this.getRest(val)
    }
  },
  beforeMount() {

  },
  mounted() {
    this.$store.dispatch("getUser");
    this.$store.dispatch("getSelArea");
    this.$store.dispatch("getSelCity");

    this.AboutCo = window.AboutCompany;
    this.CRights = window.copyrights;
    var callInit = setInterval(() => {
      //console.log(window.appInit)
      if (window.appInit) {
        window.appInit()
        clearInterval(callInit)
      }
    }, 500);
    setTimeout(() => {
      let curPage = this.$route.name
      document.title = 'Chiosco Della Pasta (' + curPage.replace('_', ' ') + ' page)'
    }, 300);

    this.$store.state.vatRate = window.vatRate
  },
  updated() {
    const app = this.$refs.app
    if (app) {
      app.scrollIntoView({ behavior: "smooth" })

    }

  }
};
</script>
<style>
#app {
  font-family: Tahoma, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
}

select:valid,
textarea:valid,
input:valid {
  border-bottom: default !important;
}

select:invalid,
textarea:invalid,
input:invalid {
  border-bottom: solid 1px rgb(172, 13, 13) !important;
}
</style>