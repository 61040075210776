<template>
  <div>

    <div>
      <img src="/img/home/HomeHeader.jpeg" style="width: 100%;" />
      <!-- <div class="home-header-after"></div> -->
    </div>
    <!-- red bar (hidden) -->
    <div v-show="false" class="container">
      <div class="p-3 bg-primary bg-primary mt-n3 rounded position-relative">
        <div class="d-flex align-items-center">
          <div class="feather_icon">
            <a href="#ratings-and-reviews" class="text-decoration-none text-dark"><i class="
                  p-2
                  bg-light
                  rounded-circle
                  font-weight-bold
                  feather-upload
                "></i></a>
            <a href="#ratings-and-reviews" class="text-decoration-none text-dark mx-2"><i class="
                  p-2
                  bg-light
                  rounded-circle
                  font-weight-bold
                  feather-star
                "></i></a>
            <a href="#ratings-and-reviews" class="text-decoration-none text-dark"><i class="
                  p-2
                  bg-light
                  rounded-circle
                  font-weight-bold
                  feather-map-pin
                "></i></a>
          </div>
          <a href="contact-us" class="btn btn-sm btn-outline-light ml-auto">Contact</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["menu", "branchs", "branch"],
};
</script>