<template>
  <nav id="main-nav">
    <ul class="second-nav">
      <li v-if="user_id != 0">
        <a href="/my_order" class="widget-header mr-3">My Orders</a>
      </li>
      
      <li>
        <a href="/about_us" class="widget-header mr-3">About Us</a>
      </li>
      <li>
        <a href="/our_chefs" class="widget-header mr-3">Our Chefs</a>
      </li>
      <li>
        <a href="/kitchen_tour" class="widget-header mr-3">Kitchen Tour</a>
      </li>
      <li>
        <a href="/pasta_history" class="widget-header mr-3">Pasta History</a>
      </li>
      <li v-if="user_id != 0"><a href="contact-us">Contact Us</a></li>
      <li v-if="user_id != 0">
          <a href="#">User</a> 
        <ul>
          <li><a href="/profile">Account Settings</a></li>
          <!-- <li>
            <a :href="CustomerType != ''
              ? '/login?redirect=/checkout/' +
              CustomerType +
              '/' +
              filledParam
              : '/login'
              ">Login</a>
          </li> -->
          <!-- <li>
            <a :href="CustomerType != ''
              ? '/signup?redirect=/checkout/' +
              CustomerType +
              '/' +
              filledParam
              : '/signup'
              ">Register</a>
          </li> -->
          <li>
            <a href="/change_password">Change Password</a>
          </li>
          <li>
            <a class="dropdown-item" href="/login">Logout</a>
          </li>
          <!-- <li><a href="verification">Verification</a></li> -->
          <!-- <li><a href="location">Location</a></li> -->
        </ul>
      </li>
      <!-- <li>
          <a href="favorites"
            ><i class="feather-heart mr-2"></i> Favorites</a
          >
        </li> -->
      <!-- <li>
          <a href="trending"
            ><i class="feather-trending-up mr-2"></i> Trending</a
          >
        </li>
        <li>
          <a href="most_popular"
            ><i class="feather-award mr-2"></i> Most Popular</a
          >
        </li>
        <li>
          <a href="restaurant"
            ><i class="feather-paperclip mr-2"></i> Restaurant Detail</a
          >
        </li> -->
      <!-- <li>
          <a href="checkout"><i class="feather-list mr-2"></i> Checkout</a>
        </li> -->
      <!-- <li>
          <a href="successful"
            ><i class="feather-check-circle mr-2"></i> Successful</a
          >
        </li> -->
      <!-- <li>
          <a href="map"><i class="feather-map-pin mr-2"></i> Live Map</a>
        </li> -->
      <!-- <li>
          <a href="#"><i class="feather-alert-triangle mr-2"></i> Error</a>
          <ul>
            <li><a href="not-found">Not Found</a></li>
            <li><a href="maintence"> Maintence</a></li>
            <li><a href="coming-soon">Coming Soon</a></li>
          </ul>
        </li> -->
      <!-- <li>
          <a href="#"
            ><i class="feather-link mr-2"></i> Navigation Link Example</a
          >
          <ul>
            <li>
              <a href="#">Link Example 1</a>
              <ul>
                <li>
                  <a href="#">Link Example 1.1</a>
                  <ul>
                    <li><a href="#">Link</a></li>
                    <li><a href="#">Link</a></li>
                    <li><a href="#">Link</a></li>
                    <li><a href="#">Link</a></li>
                    <li><a href="#">Link</a></li>
                  </ul>
                </li>
                <li>
                  <a href="#">Link Example 1.2</a>
                  <ul>
                    <li><a href="#">Link</a></li>
                    <li><a href="#">Link</a></li>
                    <li><a href="#">Link</a></li>
                    <li><a href="#">Link</a></li>
                  </ul>
                </li>
              </ul>
            </li>
            <li><a href="#">Link Example 2</a></li>
            <li><a href="#">Link Example 3</a></li>
            <li><a href="#">Link Example 4</a></li>
            <li data-nav-custom-content>
              <div class="custom-message">
                You can add any custom content to your navigation items. This
                text is just an example.
              </div>
            </li>
          </ul>
        </li> -->
    </ul>
    <ul class="bottom-nav">
      <li class="email">
        <a class="text-danger" href="/home">
          <p class="h5 m-0"><i class="feather-home text-danger"></i></p>
          Home
        </a>
      </li>
      <!-- <li class="github">
          <a href="faq">
            <p class="h5 m-0"><i class="feather-message-circle"></i></p>
            FAQ
          </a>
        </li> -->
      <li class="ko-fi">
        <a href="/contact-us">
          <p class="h5 m-0"><i class="feather-phone"></i></p>
          Help
        </a>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      tableParam: "",
      roomParam: "",
      filledParam: "",
      CustomerType: "",
      user_id: null,
    };
  },
  name: "siteNav",
  props: {},
  mounted() {
    this.$store.dispatch("getUser");
    if (typeof this.$store.state.curUser == "string") {
      this.user_id = JSON.parse(this.$store.state.curUser).user.id;
    }

    this.tableParam =
      this.$route.params.Table != undefined ? this.$route.params.Table : "";
    this.roomParam =
      this.$route.params.Room != undefined ? this.$route.params.Room : "";
    this.CustomerType =
      this.tableParam != "" ? "table" : this.roomParam != "" ? "room" : "";
    this.filledParam =
      this.CustomerType == "room"
        ? this.roomParam
        : this.CustomerType == "table"
          ? this.tableParam
          : "";
  },
};
</script>