<template>
  <div class="
      osahan-menu-fotter
      fixed-bottom
      bg-white
      px-3
      py-2
      text-center
      bg-secondary
    ">
    <div class="row">
      <div class="col">
        <a href="home" class="small font-weight-bold text-decoration-none">
          <p class="h4 m-0"><i class="feather-home" style="color:#bd946d"></i></p>
          Home
        </a>
      </div>
      <div v-if="user_id != 0" class="col">
        <a href="my_order" class="small font-weight-bold text-decoration-none">
          <p class="h4 m-0"><i class="feather-list" style="color:#bd946d"></i></p>
          My Orders
        </a>
      </div>
      <div v-if="user_id != 0" class="bg-secondary cart-middle col rounded-circle mt-n4 px-3 py-2">
        <div v-if="showCart > 0" class="border border-primary border-2 rounded-circle mt-0 shadow">
          <a href="checkout" class="text-white small font-weight-bold text-decoration-none">
            <i style="display: inline-block;width: 30px;height: 38px;mask-size: 30px 30px !important;"
              class="social-icon cart-icon"></i>
            <div
              style="position: relative;margin-top: -34px;text-align: center;width: 100%;font-size: 0.8rem;font-weight: normal">
              {{ showCart }}
            </div>
          </a>
        </div>
        <div v-if="showCart == 0" class="bg-secondary border-2 rounded-circle mt-0 shadow"
          style="background: #ffffff33 !important;">
          <a class="text-white small font-weight-bold text-decoration-none">
            <i class="social-icon cart-icon"
              style="background-color: silver;display: inline-block;width: 30px;height: 38px;mask-size: 30px 30px !important;"></i>
            <div
              style="position: relative;margin-top: -34px;text-align: center;width: 100%;font-size: 0.8rem;font-weight: normal">
              0
            </div>
          </a>
        </div>
      </div>
      <div class="col">
        <a href="contact-us" class="small font-weight-bold text-decoration-none">
          <p class="h4 m-0"><i class="feather-phone" style="color:#bd946d"></i></p>
          Help
        </a>
      </div>
      <div v-if="user_id != 0" class="col">
        <a href="profile" class="small font-weight-bold text-decoration-none">
          <p class="h4 m-0"><i class="header-icon user-icon" style="color:#bd946d"></i></p>
          Account settings
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      tableParam: "",
      roomParam: "",
      filledParam: "",
      CustomerType: "",
      user_id: null,
    };
  },
  name: "subFooter",
  props: {},
  computed: {
    ...mapState(["curOrder"]),
    ...mapActions(["UpdateCurrentOrder"]),
    showCart() {
      this.$store.dispatch("UpdateCurrentOrder");
      let ItemsCount = 0
      if (this.curOrder && this.curOrder.items) {
        for (let index = 0; index < this.curOrder.items.length; index++) {
          ItemsCount += this.curOrder.items[index].count;
        }
      }
      return ItemsCount
    }
  },
  mounted() {
    this.$store.dispatch("getUser");
    if (typeof this.$store.state.curUser == "string") {
      this.user_id = JSON.parse(this.$store.state.curUser).user.id;
    }

    this.tableParam =
      this.$route.params.Table != undefined ? this.$route.params.Table : "";
    this.roomParam =
      this.$route.params.Room != undefined ? this.$route.params.Room : "";
    this.CustomerType =
      this.tableParam != "" ? "table" : this.roomParam != "" ? "room" : "";
    this.filledParam =
      this.CustomerType == "room"
        ? this.roomParam
        : this.CustomerType == "table"
          ? this.tableParam
          : "";
  },
};
</script>