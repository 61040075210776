const initCityId = window.initCityId
const initAreaId = window.initAreaId
const initBaseURL = window.baseURL

import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    curUser: {},
    baseURL: initBaseURL,
    selArea: null,
    curAreaName: null,
    selCity: null,
    selAddress: null,
    restaurant: null,
    vatRate: 14,
    curOrder: null,


  }, getters: {
    getSelArea(state) {
      return state.selArea
    },
  },
  mutations: {
    curAreaName(state, name) {
      state.curAreaName = name
    },
    setSelAddress(state, id) {
      state.selAddress = id
    },
    setCurOrder(state, order) {
      state.curOrder = order
      window.localStorage.setItem("curOrder", JSON.stringify(order))
    },
    setUser(state, newUser) {
      if (newUser) {
        //console.log(newUser)
        state.curUser = newUser
      }
    },
    setSelArea(state, id) {
      state.selArea = id
      window.localStorage.setItem('selArea', id)
    },
    setSelCity(state, city) {
      state.selCity = city
      window.localStorage.setItem('selCity', city)
    },
    setRestaurant(state, rest) {
      state.restaurant = rest
    }
  },
  actions: {
    UpdateCurrentOrder() {
      let order = window.localStorage.getItem('curOrder')
      
      if (order) {
        this.commit('setCurOrder', JSON.parse(order))
        
      } else {
        this.commit('setCurOrder', { "address": null, "notes": null, "items": [] })
      }
    },
    getUser() {
      if (window.localStorage.getItem('curUser')) {
        this.commit('setUser', window.localStorage.getItem('curUser'))
      } else {
        this.commit('setUser', {})
      }
      return this.curUser
    },
    getSelArea() {
      if (window.localStorage.getItem('selArea')) {
        this.commit('setSelArea', window.localStorage.getItem('selArea'))
      } else {
        this.commit('setSelArea', initAreaId)
      }
    },
    getSelCity() {
      if (window.localStorage.getItem('selCity')) {
        this.commit('setSelCity', window.localStorage.getItem('selCity'))
      } else {
        this.commit('setSelCity', initCityId)
      }
    }
  },
  modules: {
  }

})
