import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'root',
    meta: { requiresAuth: false },
    component: Home,
  },
  {
    path: '/home',
    name: 'Home',
    meta: { requiresAuth: false },
    component: Home,
  },
  {
    path: '/home/room/:Room',
    name: 'HomeRoom',
    meta: { requiresAuth: false },
    component: Home,
  },
  {
    path: '/home/table/:Table',
    name: 'HomeTable',
    meta: { requiresAuth: false },
    component: Home,
  },
  {
    path: '/login',
    name: 'login',
    meta: { requiresAuth: false },
    component: () => import('../views/login.vue'),
  },
  {
    path: '/successful',
    name: 'successful',
    meta: { requiresAuth: false },
    component: () => import('../views/successful.vue'),
  },
  {
    path: '/checkout',
    name: 'checkout',
    meta: { requiresAuth: true },
    component: () => import('../views/checkout.vue'),
  },
  {
    path: '/checkout/room/:Room',
    name: 'checkoutRoom',
    meta: { requiresAuth: true },
    component: () => import('../views/checkout.vue'),
  },
  {
    path: '/checkout/table/:Table',
    name: 'checkoutTable',
    meta: { requiresAuth: true },
    component: () => import('../views/checkout.vue'),
  },
  {
    path: '/my_order',
    name: 'my_order',
    meta: { requiresAuth: true },
    component: () => import('../views/my_order.vue'),
  },
  {
    path: '/about_us',
    name: 'about_us',
    meta: { requiresAuth: false },
    component: () => import('../views/about_us.vue'),
  },
  {
    path: '/our_chefs',
    name: 'our_chefs',
    meta: { requiresAuth: false },
    component: () => import('../views/our_chefs.vue'),
  },
  {
    path: '/kitchen_tour',
    name: 'kitchen_tour',
    meta: { requiresAuth: false },
    component: () => import('../views/kitchen_tour.vue'),
  },
  {
    path: '/pasta_history',
    name: 'pasta_history',
    meta: { requiresAuth: false },
    component: () => import('../views/pasta_history.vue'),
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    meta: { requiresAuth: true },
    component: () => import('../views/contactUs.vue'),
  },
  {
    path: '/success_contact',
    name: 'success_contact',
    meta: { requiresAuth: true },
    component: () => import('../views/success_contact.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    meta: { requiresAuth: true },
    component: () => import('../views/profile.vue'),
  },
  {
    path: '/success_save',
    name: 'success_save',
    meta: { requiresAuth: false },
    component: () => import('../views/success_save.vue'),
  },
  {
    path: '/signup',
    name: 'signup',
    meta: { requiresAuth: false },
    component: () => import('../views/signup.vue'),
  },
  {
    path: '/forgot_password',
    name: 'forgot_password',
    meta: { requiresAuth: false },
    component: () => import('../views/forgot_password.vue'),
  },
  {
    path: '/change_password',
    name: 'change_password',
    meta: { requiresAuth: false },
    component: () => import('../views/change_password.vue'),
  },
  {
    path: '/privacy_policy',
    name: 'privacy_policy',
    meta: { requiresAuth: false },
    component: () => import('../views/privacy_policy.vue'),
  },
  {
    path: '/terms',
    name: 'terms',
    meta: { requiresAuth: false },
    component: () => import('../views/terms.vue'),
  },
  {
    path: '/refund_policy',
    name: 'refund_policy',
    meta: { requiresAuth: false },
    component: () => import('../views/refund_policy.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (window.localStorage.getItem('jwt')) {
      next();
    } else {
      next({ name: 'login', query: { redirect: to.fullPath } });
    }
  } else {
    next();
  }
});
export default router;
