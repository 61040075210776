<template>
  <div>
    <sub-footer />
    <footer class="section-footer border-top bg-dark">
      <div class="container text-left">
        <section class="footer-top py-2">
          <div class="row">
            <aside class="col-md-4">
              <article class="d-flex pb-3">
                <!-- <div v-if="$store.state.restaurant">
                  <img :src="
  $store.state.baseURL + '/' + $store.state.restaurant.image
" class="logo-footer mr-3" />
                </div> -->
                <div class="mr-3 d-flex">
                  <img src="/img/home/ChioscoLogo.png" style="height: 70px;" />
                  <img src="/img/home/LogoChifLight.png" style="height: 70px;" />
                </div>
              </article>
            </aside>

            <aside class="col-sm-3 col-md-2 text-white">
              <ul class="list-unstyled hov_footer">
                <li>
                  <a href="/about_us" class="text-muted">About Us</a>
                </li>
                 <li>
                  <a href="/contact-us" class="text-muted">Contact Us</a>
                </li>
              </ul>
            </aside>
            <aside class="col-sm-3 col-md-2 text-white">
              <ul class="list-unstyled hov_footer">
                <li>
                  <a href="/our_chefs" class="text-muted">Our Chefs</a>
                </li>
                <li>
                  <a href="/kitchen_tour" class="text-muted">Kitchen Tour</a>
                </li>
              </ul>
            </aside>
            <aside class="col-md-4 footer-about">
              <article class="d-flex">
                <div>
                  <p class="text-muted">Follow Us</p>
                  <div v-if="socialLinks" class="d-flex align-items-center">
                    <a v-for="link in socialLinks" v-bind:key="link.title"
                      class="btn mr-1 btn-sm border-0 mx-1" :title="link.title" target="_blank"
                      :href="link.href">
                      <i :class="link.iconClass" style="width:20px;height:20px;padding:4px;"></i>
                    </a>
                  </div>
                </div>
              </article>
            </aside>

          </div>
          <!-- row.// -->
        </section>
      </div>
      <!-- //container -->
      <section class="footer-copyright border-top py-2 bg-light">
        <div class="container d-flex align-items-center">
          <p class="mb-0">{{ Copyrights }}</p>
          <p v-if="appleStoreUrl && playStoreUrl" class="text-muted mb-0 ml-auto d-flex align-items-center">
            <a :href="appleStoreUrl" class="d-block"><img alt="Download from Apple Store" src="img/appstore.png"
                height="40" /></a>
            <a :href="playStoreUrl" class="d-block ml-3"><img alt="Download fron Google Play Store"
                src="img/playmarket.png" height="40" /></a>
          </p>
        </div>
      </section>
    </footer>
  </div>
</template>

<script>
import subFooter from "./subFooter.vue";
export default {
  components: { subFooter },
  data() {
    return {
      tableParam: "",
      roomParam: "",
      filledParam: "",
      CustomerType: "",
      user_id: null,
      appleStoreUrl: null,
      playStoreUrl: null,
      socialLinks: []
    };
  },
  name: "siteFooter",
  props: {
    AboutCo: String,
    Copyrights: String,
  },
  mounted() {
    this.appleStoreUrl = window.appleStoreUrl
    this.playStoreUrl = window.playStoreUrl
    this.socialLinks = window.socialLinks

    this.$store.dispatch("getUser");
    if (typeof this.$store.state.curUser == "string") {
      this.user_id = JSON.parse(this.$store.state.curUser).user.id;
    }
    this.tableParam =
      this.$route.params.Table != undefined ? this.$route.params.Table : "";
    this.roomParam =
      this.$route.params.Room != undefined ? this.$route.params.Room : "";
    this.CustomerType =
      this.tableParam != "" ? "table" : this.roomParam != "" ? "room" : "";
    this.filledParam =
      this.CustomerType == "room"
        ? this.roomParam
        : this.CustomerType == "table"
          ? this.tableParam
          : "";
  },
};
</script>