<template>
  <div>
    <site-nav />
    <header class="section-header d-none d-lg-block">
      <section class="header-main shadow-sm">
        <div class="container">
          <div class="row">
            <div class="col-1 p-0 m-0">
              <a :href="'/home' + (curTUrl != '' ? curTUrl : curRUrl)" class="m-1">
                <!-- <img
                  class="img-fluid" style="max-height:40px;"
                  :src="$store.state.baseURL + '/' + restaurant.image"
                /> -->
                <img src="/img/home/ChioscoLogo.png" style="height:50px; margin: 7px 0;" />
              </a>
              <!-- brand-wrap.// -->
            </div>
            <div style="display: none !important;" class="col-2 d-flex align-items-center">
              <div class="dropdown mr-3">
                <a class="
                    text-dark
                    dropdown-toggle
                    d-flex
                    align-items-center
                    py-3
                  " href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">
                  <div>
                    <i class="
                        feather-map-pin
                        mr-2
                        bg-light
                        rounded-pill
                        p-2
                        icofont-size
                      "></i>
                  </div>
                  <div class="text-center" v-if="areas">
                    <p class="text-muted mb-0 small">Select Area</p>
                    {{ curAreaName }}
                  </div>
                </a>
                <div style="
                    max-height: 487px;
                    overflow: hidden;
                    overflow-y: scroll;
                  " class="dropdown-menu p-0 drop-loc" aria-labelledby="navbarDropdown">
                  <div class="osahan-country">
                    <div style="position: sticky; top: 0px; z-index: 100"
                      class="search_location bg-primary p-3 text-right">
                      <div class="input-group rounded shadow-sm overflow-hidden">
                        <div class="input-group-prepend">
                          <button v-on:click="getAreas" class="
                              border-0
                              btn btn-outline-secondary
                              text-dark
                              bg-white
                              btn-block
                            ">
                            <i class="feather-search"></i>
                          </button>
                        </div>
                        <select id="ddlCities" v-model="selCity" v-on:change="setSelCity"
                          placeholder="Enter Your Location" class="shadow-none border-0 form-control">
                          <option v-for="option in cities" v-bind:key="option.id" :value="option.id">
                            {{ option.name_en }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="p-3 border-bottom">
                      <a href="/home" class="text-decoration-none">
                        <p v-if="cities && selCity" class="font-weight-bold text-primary m-0">
                          <i class="feather-navigation"></i>
                          {{ cities.find((c) => c.id == selCity).name_en }}
                        </p>
                      </a>
                    </div>
                    <div class="filter">
                      <h6 class="px-3 py-3 bg-light pb-1 m-0 border-bottom">
                        Choose your Area
                      </h6>
                      <div v-for="item in areas" v-on:click="setSelArea(item.id)" v-bind:key="item.id"
                        class="custom-control border-bottom px-0 custom-radio">
                        <input :value="item.id" v-model="selArea" type="radio" :id="'area' + item.id" name="area"
                          class="custom-control-input" />
                        <label class="custom-control-label py-3 w-100 px-3" :for="'area' + item.id">{{ item.area_name_en
                          }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- col.// -->
            <div class="col-11 pl-4 text-left">
              <div class="d-flex align-items-center h-100 ">
                <!-- home -->
                <!-- <a :href="'/home'+(curTUrl!=''?curTUrl:curRUrl)" class="widget-header mr-4 text-dark">
                  <div class="icon d-flex align-items-center">
                    <i class="feather-home h6 mr-2 mb-0"></i>
                    <span>Home</span>
                  </div>
                </a> -->
                <div class="col-8 d-flex justify-content-between">
                  <a href="/home" class="home-mi widget-header">Home</a>
                  <a href="/my_order" class="home-mi widget-header">My Orders</a>
                  <a href="/about_us" class="home-mi widget-header">About Us</a>
                  <a href="/our_chefs" class="home-mi widget-header">Our Chefs</a>
                  <a href="/kitchen_tour" class="home-mi widget-header">Kitchen Tour</a>
                  <a href="/pasta_history" class="home-mi widget-header">Pasta History</a>
                </div>
                <!-- search -->
                <!-- <a href="/search" class="widget-header mr-4 text-dark">
                  <div class="icon d-flex align-items-center">
                    <i class="feather-search h6 mr-2 mb-0"></i>
                    <span>Search</span>
                  </div>
                </a> -->
                <!-- offers -->
                <!-- <a
                href="/offers"
                class="widget-header mr-4 text-white btn bg-primary m-none"
              >
                <div class="icon d-flex align-items-center">
                  <i class="feather-disc h6 mr-2 mb-0"></i> <span>Offers</span>
                </div>
              </a> -->

                <!-- signin -->
                <div v-if="false" class="d-flex ml-auto mr-1">
                  <a href="/login" v-show="JSON.stringify($store.state.curUser).length == 2"
                    class="widget-header mr-3 py-3">
                    <div class="icon d-flex align-items-center">
                      <i class="feather-user h6 mr-2 mb-0"></i>
                      <span>Sign in</span>
                    </div>
                  </a>
                  <!-- signin -->
                  <a :href="'/checkout' + (curTUrl != '' ? curTUrl : curRUrl)" class="widget-header mr-2 py-3"
                    style="line-height: 31px">
                    <div class="icon d-flex align-items-center">
                      <div style="margin-top: -19px; margin-right: -24px !important;line-height: 18px;;"
                        class="small bg-dark shadow-sm rounded-pill px-1 mx-1 text-white">
                        {{ showCart }}
                      </div>
                      <i class="feather-shopping-cart h6 mr-2 mb-0"></i>
                      <span>Cart</span>
                    </div>
                  </a>

                  <!-- my account -->
                  <div class="mr-3" v-if="user_id == 0">
                    <img :src="JSON.parse($store.state.curUser).avatar != 'default.png'
                      ? JSON.parse($store.state.curUser).avatar
                      : ''
                      " class="
                        img-fluid
                        rounded-circle
                        header-user
                        mr-2
                        header-user
                      " />
                    Hi {{ JSON.parse($store.state.curUser).user.user_name }}
                  </div>
                  <div v-if="$store.state.curUser.length > 2 && user_id != 0" class="dropdown mr-3">
                    <a href="/#" class="dropdown-toggle text-dark py-3 d-block" id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <img :src="JSON.parse($store.state.curUser).avatar != 'default.png'
                        ? JSON.parse($store.state.curUser).avatar
                        : ''
                        " class="
                        img-fluid
                        rounded-circle
                        header-user
                        mr-2
                        header-user
                      " />
                      Hi {{ JSON.parse($store.state.curUser).user.user_name }}
                    </a>
                    <div style="margin-top: -5px;" class="dropdown-menu dropdown-menu-right"
                      aria-labelledby="dropdownMenuButton">
                      <a v-if="user_id != 0" class="dropdown-item" href="/profile">Account Settings</a>
                      <a v-if="user_id != 0" class="dropdown-item" href="/my_order">My Orders</a>
                      <a v-if="user_id != 0" class="dropdown-item" href="/change_password">Change Password</a>
                      <!-- <a v-if="user_id != 0" class="dropdown-item" href="/signup">Register</a> -->
                      <!-- <a class="dropdown-item" href="/contact-us">Contant us</a>
                      <a class="dropdown-item" href="/terms">Term of use</a>
                      <a class="dropdown-item" href="/privacy_policy">Privacy policy</a>
                      <a class="dropdown-item" href="/refund_policy">Refund policy</a> -->
                      <a class="dropdown-item" href="/login">Logout</a>
                    </div>
                  </div>

                  <a v-if="user_id != 0" style="margin-right: -30px;"
                    class="toggle hc-nav-trigger hc-nav-1 d-block d-lg-none mt-3" href="#" role="button"
                    aria-controls="hc-nav-1">
                    <span></span>
                  </a>
                </div>
                <div class="d-flex ml-auto mr-1">

                  <!-- user -->
                  <a v-show="JSON.stringify($store.state.curUser).length > 2" href="/profile"
                    class="home-mi widget-header mr-2 py-3" style="line-height: 31px">
                    <div class="icon d-flex align-items-center">
                      <i class="header-icon user-icon h6 mr-2 mb-0"></i>
                    </div>
                  </a>
                  <!-- Cart -->
                  <a v-show="JSON.stringify($store.state.curUser).length > 2"
                    :href="'/checkout' + (curTUrl != '' ? curTUrl : curRUrl)" class="home-mi widget-header mr-2 py-3"
                    style="line-height: 31px">
                    <div class="icon d-flex align-items-center">
                      <div style="margin-top: -23px;
                                  margin-right: -23px !important;
                                  line-height: 15px;
                                  min-width: 14px;
                                  height: 14px;
                                  font-family: Tahoma;
                                  font-size: 10px !important;
                                  background-color: rgb(214, 214, 214) !important;
                                  z-index: 1;" class="small rounded-circle px-1 mx-1 text-primary">
                        {{ showCart }}
                      </div>
                      <i class="header-icon cart-icon h6 mr-2 mb-0"></i>
                    </div>
                  </a>
                  <a href="/login" v-show="JSON.stringify($store.state.curUser).length == 2"
                    class="home-mi widget-header mr-3 py-3">
                    <div class="icon d-flex align-items-center">
                      <i class="feather-log-in"></i>
                    </div>
                  </a>
                  <a href="/login" v-show="JSON.stringify($store.state.curUser).length > 2"
                    class="home-mi widget-header mr-3 py-3">
                    <div class="icon d-flex align-items-center">
                      <i class="feather-log-out"></i>
                    </div>
                  </a>
                </div>
              </div>
              <!-- widgets-wrap.// -->
            </div>
            <!-- col.// -->
          </div>
          <!-- row.// -->
        </div>
        <!-- container.// -->
      </section>
    </header>
    <!-- Small device header -->
    <div class="d-block d-lg-none bg-secondary">
      <div class="bg-secondary border-bottom p-3 d-flex align-items-center">
        <a v-if="user_id != 0" class="toggle togglew toggle-2 hc-nav-trigger hc-nav-1 bg-transparent border-0" style=""
          role="button" aria-controls="hc-nav-1"><span></span></a>

        <h4 class="font-weight-bold m-0 text-white">
          <!-- <img class="img-fluid figure-img img-thumbnail" style="max-height: 50px"
            :src="$store.state.baseURL + '/' + restaurant.image" /> -->
          <img src="/img/logo_web.png" class="img-fluid figure-img" />
          <span v-if="restaurant" class="px-5 text-primary" style="font-family: steelfish;text-transform: uppercase;letter-spacing: 0.8vw;font-size: 4vw;line-height: normal;">{{ restaurant.res_name_en }}</span>
        </h4>
      </div>
    </div>
  </div>
</template>
<script>


import siteNav from "./siteNav.vue";

export default {
  components: { siteNav },
  name: "siteHeader",
  data() {
    return {
      areas: null,
      selCity: this.$store.state.selCity,
      selArea: this.$store.state.selArea,
      cities: null,
      user_id: null,
      curTUrl: '',
      curRUrl: '',
      showCart: 0
    };
  },
  props: {
    user: String,
    restaurant: Object,
  },
  watch: {
    areas: {
      deep: true,
      handler() {
        if (this.areas) {
          if (this.areas.length > 0) {
            this.selArea = this.$store.state.selArea;
            if (!this.$store.state.selArea) {
              let id = this.areas[0].id;
              this.setSelArea(id);
            } else {
              this.$store.commit("curAreaName", this.curAreaName);
            }
          }
        }
      },
    },
    cities() {
      this.selCity = this.$store.state.selCity;
    },
    "$store.state.selCity"(val) {
      this.selCity = val;
      this.selArea = null;
      this.getAreas();
    },
    "$store.state.selArea"(val) {
      this.selArea = val;
    },
    "$store.state.curOrder"() {
      let curOrder = JSON.parse(window.localStorage.getItem('curOrder'))
      let ItemsCount = 0

      if (curOrder && curOrder.items) {
        for (let index = 0; index < curOrder.items.length; index++) {
          ItemsCount += curOrder.items[index].count;
        }
      }

      this.showCart = ItemsCount
    }
  },
  computed: {
    curAreaName() {
      var a = this.areas.find((c) => c.id == this.selArea);
      if (a) {
        return a.area_name_en;
      }
      return "";
    },
  },
  updated() {
    this.$store.dispatch("UpdateCurrentOrder");
  },
  mounted() {
    this.$store.dispatch("getUser");
    if (typeof this.$store.state.curUser == "string") {
      this.user_id = JSON.parse(this.$store.state.curUser).user.id;
    }
    this.getCities();
    this.getAreas();
    this.curTUrl = window.location.href.toLowerCase().indexOf('/table') > 0 ? window.location.href.toLowerCase().substring(window.location.href.indexOf('/table')) : ''
    this.curRUrl = window.location.href.toLowerCase().indexOf('/room') > 0 ? window.location.href.toLowerCase().substring(window.location.href.indexOf('/room')) : ''
  },
  methods: {
    getAreas() {
      fetch(this.$store.state.baseURL + "/api/areas?city=" + this.selCity, {
        method: "GET",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            console.log(
              "Server returned " + response.status + " : " + response.statusText
            );
          }
        })
        .then((response) => {
          if (response.data) {
            this.result = response.data;
            this.areas = JSON.parse(JSON.stringify(this.result)).areas;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCities() {
      fetch(this.$store.state.baseURL + "/api/cities", {
        method: "GET",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            console.log(
              "Server returned " + response.status + " : " + response.statusText
            );
          }
        })
        .then((response) => {
          this.result = response.data;
          this.cities = JSON.parse(JSON.stringify(this.result)).cities;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setSelArea(id) {
      let curOrder = JSON.parse(window.localStorage.getItem("curOrder"))
      if (curOrder) {
        if (curOrder.length > 0) {
          curOrder = JSON.parse(curOrder);
          if (curOrder.address) {
            curOrder.address = null;
            this.$store.commit("setCurOrder", curOrder);
            
          }
        }
      }
      this.selArea = id;
      this.$store.commit("setSelArea", id);

      window.localStorage.setItem("selArea", id);
      this.$store.commit("curAreaName", this.curAreaName);
      //console.log(this.curAreaName);
    },
    setSelCity() {
      this.$store.commit("setSelCity", this.selCity);
      window.localStorage.setItem("selCity", this.selCity);
    },
  },
};
</script>