<template>
  <div>
    <div v-if="false" v-show="isLoading" style="
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1000;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.9);
        text-align: center;
      ">
      <div style="color: #fff; font-size: 3rem; margin-top: 250px">
        <div v-if="$store.state.restaurant" style="color: Orange">
          {{ $store.state.restaurant.res_name_en }}
        </div>

        <span style="font-size: 2rem"> Loading ...</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['isLoading']
}
</script>